import React, { FC } from "react";
import { Formik, FieldArray } from "formik";
import * as Yup from "yup";
import { useQuery } from "react-apollo";
import gql from "graphql-tag";
import { HorizontalTextField } from "components/formik/TextField";
import { HorizontalDateMaskField } from "components/formik/DateMaskField";
import { FormStatusErrors } from "components/formik/FormStatusErrors";
import { CaseLookupParams } from "../NewAppointmentRequestPage";
import { FAIcon } from "components/FAIcon";
import { localDateRegex } from "lib/localDateToISO";
import { HorizontalSelectField } from "components/formik/SelectField";
import { components, OptionProps } from "react-select";
import { Button } from "components/Button";

const HEALTH_PLANS = gql`
  query GetHealthPlans {
    healthPlans(filter: { active: true }) {
      id
      name
      externalSystemName
    }
  }
`;

interface Data {
  healthPlans: HealthPlan[];
}

interface HealthPlan {
  id: string;
  name: string;
  externalSystemName: string;
}

interface HealthPlanOptionModel {
  value: string;
  label: string;
  externalSystemName: string;
}

const Badge: FC<{ className?: string }> = (props) => {
  const { className = "", children } = props;
  return <span className={`text-xs ${className}`}>{children}</span>;
};

const HealthPlanOption: FC<OptionProps<
  HealthPlanOptionModel,
  false
>> = React.memo((props) => {
  return (
    <components.Option {...props}>
      <div className="flex">
        <p className="flex-grow">{props.data.label}</p>
        <Badge>{props.data.externalSystemName}</Badge>
      </div>
    </components.Option>
  );
});

const linesOfBusiness = [
  "Acupuncture",
  "BCN Migration",
  "Cardiology",
  "Chemo Pathways",
  "Chiropractic",
  "DME Management",
  "Fertility",
  "Gastroenterology",
  "IMRT",
  "Laboratory",
  "Mammography Outreach",
  "Massage Therapy",
  "Medical Oncology",
  "MSK Joint Surgery",
  "MSK Pain Management",
  "MSK Spine Surgery",
  "MSM",
  "Occupational Therapy",
  "Outreach Program",
  "PF Oncology",
  "Physical Therapy",
  "Post-Acute Care",
  "Radiation Oncology",
  "Radiation Therapy",
  "Radiology",
  "Sleep Management",
  "Specialty Drugs",
  "Speech Therapy",
  "Ultrasound",
];

const lineOfBusinessOptions: Option[] = linesOfBusiness.map((lob) => ({
  value: lob,
  label: lob,
}));

const newCaseLookup = {
  healthPlanId: "",
  lineOfBusiness: "",
  caseReferenceNumber: "",
  memberDob: "",
};

const currentYear = new Date().getFullYear();
const dobMinYear = 1900;
const dobMaxYear = currentYear + 1;

interface CaseLookupFormProps {
  onSubmit(caseLookups: CaseLookupParams[]): void;
}

export const CaseLookupForm: FC<CaseLookupFormProps> = (props) => {
  const { onSubmit } = props;
  const { data, loading, error } = useQuery<Data>(HEALTH_PLANS);

  const healthPlanOptions: HealthPlanOptionModel[] =
    data?.healthPlans.map((hp) => ({
      value: hp.id,
      label: hp.name,
      externalSystemName: hp.externalSystemName,
    })) || [];

  if (error) {
    return <p>Failed to load.</p>;
  }

  return (
    <Formik
      initialValues={{
        caseLookups: [newCaseLookup],
      }}
      validationSchema={Yup.object().shape({
        caseLookups: Yup.array().of(
          Yup.object().shape({
            healthPlanId: Yup.string().required("Required"),
            lineOfBusiness: Yup.string().required("Required"),
            caseReferenceNumber: Yup.string().required("Required"),
            memberDob: Yup.string()
              .required("Required")
              .matches(localDateRegex, {
                message: "Invalid date",
              }),
          })
        ),
      })}
      onSubmit={(values, { setSubmitting }) => {
        const lookups: CaseLookupParams[] = values.caseLookups.map((cl) => ({
          healthPlanId: cl.healthPlanId,
          lineOfBusiness: cl.lineOfBusiness,
          caseReferenceNumber: cl.caseReferenceNumber,
          memberDob: cl.memberDob,
        }));

        onSubmit(lookups);
        setSubmitting(false);
      }}
    >
      {({ status, values, isSubmitting, handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <FormStatusErrors status={status} />

          <FieldArray
            name="caseLookups"
            render={(arrayHelpers) => (
              <div>
                {values.caseLookups && values.caseLookups.length > 0 ? (
                  <div>
                    {values.caseLookups.map((_cl, idx) => (
                      <div
                        key={idx}
                        className="p-4 mb-4 border border-gray-200 rounded-lg shadow-md"
                      >
                        <div className="flex items-center justify-between mb-4">
                          {values.caseLookups?.length > 1 ? (
                            <p
                              className="mb-4 text-sm text-gray-700"
                              style={{ margin: 0 }}
                            >
                              Case #{idx + 1}
                            </p>
                          ) : (
                            <div />
                          )}
                          {idx > 0 ? (
                            <button
                              type="button"
                              className="text-sm font-semibold text-red-500 bg-transparent"
                              style={{
                                border: "none",
                                padding: 0,
                                display: "inline",
                                cursor: "pointer",
                              }}
                              onClick={() => arrayHelpers.remove(idx)}
                            >
                              Remove
                            </button>
                          ) : null}
                        </div>

                        <HorizontalTextField
                          name={`caseLookups[${idx}].caseReferenceNumber`}
                          label="Case Reference Number"
                        />

                        <div className="mt-2">
                          <HorizontalDateMaskField
                            name={`caseLookups[${idx}].memberDob`}
                            label="Member Date of Birth"
                            minYear={dobMinYear}
                            maxYear={dobMaxYear}
                          />
                        </div>

                        <div className="mt-2">
                          <HorizontalSelectField
                            name={`caseLookups[${idx}].lineOfBusiness`}
                            label="Program"
                            options={lineOfBusinessOptions}
                          />
                        </div>

                        <div className="mt-2">
                          <HorizontalSelectField
                            name={`caseLookups[${idx}].healthPlanId`}
                            label="Health Plan"
                            options={healthPlanOptions}
                            isLoading={loading}
                            components={{ Option: HealthPlanOption }}
                          />
                        </div>
                      </div>
                    ))}
                    {values.caseLookups && values.caseLookups.length < 4 ? (
                      <div className="text-center">
                        <Button
                          type="button"
                          kind="tertiary"
                          className="border"
                          size="xs"
                          onClick={() => arrayHelpers.push(newCaseLookup)}
                        >
                          <span className="mr-2">
                            <FAIcon icon="plus" />
                          </span>
                          Add Case Lookup
                        </Button>
                      </div>
                    ) : null}
                  </div>
                ) : (
                  <button type="button">Add</button>
                )}
              </div>
            )}
          />

          <div className="flex justify-end mt-6">
            <Button
              type="submit"
              kind="primary"
              color="gold"
              size="lg"
              disabled={isSubmitting}
              isLoading={isSubmitting}
            >
              {values.caseLookups && values.caseLookups.length > 1
                ? "Lookup Cases"
                : "Lookup Case"}
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
};
