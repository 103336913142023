import { FC } from "react";
import { Formik, FieldArray } from "formik";
import gql from "graphql-tag";
import * as Yup from "yup";
import { useQuery, useMutation } from "react-apollo";
import { useToast } from "layouts/PortalLayout/Toast";
import { Spinner } from "components/Spinner";
import { FormStatusErrors } from "components/formik/FormStatusErrors";
import { TextField } from "components/formik/TextField";
import { VerticalField } from "components/formik/FieldStructure";
import { SelectInput } from "components/formik/SelectField";
import { FAIcon } from "components/FAIcon";
import { Button } from "components/Button";
import { ToggleSwitchField } from "components/formik/ToggleSwitchField";

const FETCH_PROVIDER_AND_OPTIONS = gql`
  query GetProviderAndOptions($id: UUID4!) {
    provider(id: $id) {
      id
      email
      firstName
      lastName
      iexId
      domainId
      imageoneId
      isSme
      skills {
        id
      }
      specialties {
        id
      }
      stateLicensures {
        id
      }
    }
    states {
      id
      name
      abbreviation
    }
    skills {
      id
      name
    }
    specialties {
      id
      name
    }
  }
`;

interface Data {
  provider: {
    id: string;
    email: string;
    firstName: string;
    lastName: string;
    iexId: string;
    domainId: string;
    imageoneId: null | string;
    isSme: boolean;
    skills: { id: string }[];
    specialties: { id: string }[];
    stateLicensures: { id: string }[];
  };
  states: State[];
  skills: Skill[];
  specialties: Specialty[];
}

interface State {
  id: string;
  name: string;
  abbreviation: string;
}

interface Skill {
  id: string;
  name: string;
}

interface Specialty {
  id: string;
  name: string;
}

const UPDATE_PROVIDER = gql`
  mutation UpdateProvider($id: UUID4!, $input: CreateProviderInput!) {
    updateProvider(id: $id, input: $input) {
      errors {
        key
        message
      }
      provider {
        id
      }
    }
  }
`;

interface MutationData {
  updateProvider: {
    errors?: InputError[];
    provider?: {
      id: string;
    };
  };
}

interface FormValues {
  firstName: string;
  lastName: string;
  iexId: string;
  email: string;
  domainId: string;
  imageoneId: string;
  isSme: boolean;
  skillIds: string[];
  stateIds: string[];
  specialtyIds: string[];
}

interface EditProviderFormProps {
  id: string;
  refetchQueries: any[];
  onSuccess(): void;
}

export const EditProviderForm: FC<EditProviderFormProps> = (props) => {
  const { id, refetchQueries, onSuccess } = props;
  const toast = useToast();
  const { data, loading, error } = useQuery<Data>(FETCH_PROVIDER_AND_OPTIONS, {
    variables: { id },
  });
  const [updateProvider] = useMutation<MutationData>(UPDATE_PROVIDER);

  return (
    <div className="_EditProviderForm p-4">
      {loading ? (
        <div>
          <Spinner />
        </div>
      ) : error || !(data && data.provider) ? (
        <p>Failed to load.</p>
      ) : (
        <Formik<FormValues>
          initialValues={{
            firstName: data.provider.firstName,
            lastName: data.provider.lastName,
            email: data.provider.email,
            iexId: data.provider.iexId,
            isSme: data.provider.isSme,
            domainId: data.provider.domainId,
            imageoneId: data.provider.imageoneId || "",
            skillIds: data.provider.skills.map((s) => s.id),
            specialtyIds: data.provider.specialties.map((s) => s.id),
            stateIds: data.provider.stateLicensures.map((s) => s.id),
          }}
          validationSchema={Yup.object().shape({
            firstName: Yup.string().required("Required"),
            lastName: Yup.string().required("Required"),
            iexId: Yup.string().required("Required"),
            isSme: Yup.boolean().required("Required"),
            email: Yup.string().required("Required"),
            domainId: Yup.string().required("Required"),
            skillIds: Yup.array().of(Yup.string().required("Required")),
            stateIds: Yup.array().of(Yup.string().required("Required")),
            specialtyIds: Yup.array().of(Yup.string().required("Required")),
          })}
          onSubmit={(values, { setStatus, setSubmitting }) => {
            setStatus({ errors: null });
            updateProvider({
              variables: { id, input: values },
              refetchQueries,
            }).then((resp) => {
              if (resp?.data?.updateProvider.errors) {
                setStatus({
                  errors: resp.data.updateProvider.errors,
                });
              } else if (resp?.data?.updateProvider.provider) {
                // It worked...
                toast.success(`Provider updated.`);
                if (onSuccess) {
                  return onSuccess();
                }
              }
              setSubmitting(false);
            });
          }}
        >
          {({ values, status, isSubmitting, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <FormStatusErrors status={status} />
              <div className="gap-x-2 grid grid-cols-2">
                <TextField name="firstName" label="First Name" />
                <TextField name="lastName" label="Last Name" />
              </div>
              <div className="mt-3">
                <TextField name="email" label="Email" />
              </div>
              <div className="mt-3">
                <TextField name="domainId" label="Domain ID" />
              </div>
              <div className="mt-3">
                <TextField name="imageoneId" label="ImageOne ID" />
              </div>
              <div className="mt-3">
                <TextField name="iexId" label="IEX ID" />
              </div>

              <div className="mt-3">
                <ToggleSwitchField name="isSme" label="Is SME" />
              </div>

              <div className="mt-3">
                <VerticalField label="Skills">
                  <FieldArray
                    name="skillIds"
                    render={(arrayHelpers) => (
                      <div>
                        {values.skillIds && values.skillIds.length > 0 ? (
                          <div className="mb-2">
                            {values.skillIds.map((_skillId, index) => (
                              <div key={index} className="mb-2">
                                <div className="flex items-center">
                                  <div className="flex-grow">
                                    <SelectInput
                                      name={`skillIds.${index}`}
                                      options={
                                        data?.skills?.map((s) => ({
                                          value: s.id,
                                          label: s.name,
                                        })) || []
                                      }
                                      isLoading={loading}
                                    />
                                  </div>
                                  <div className="ml-2">
                                    <Button
                                      type="button"
                                      kind="tertiary"
                                      color="red"
                                      size="sm"
                                      className="border"
                                      onClick={() => arrayHelpers.remove(index)}
                                    >
                                      <FAIcon icon="times" />
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            ))}
                            <div className="text-center">
                              <Button
                                type="button"
                                kind="tertiary"
                                color="blue"
                                size="xs"
                                onClick={() => arrayHelpers.push("")}
                              >
                                Add another skill
                              </Button>
                            </div>
                          </div>
                        ) : (
                          <div className="text-center">
                            <Button
                              type="button"
                              kind="tertiary"
                              color="blue"
                              size="xs"
                              onClick={() => arrayHelpers.push("")}
                            >
                              Add a skill
                            </Button>
                          </div>
                        )}
                      </div>
                    )}
                  />
                </VerticalField>
              </div>

              <div className="mt-3">
                <VerticalField label="Specialties">
                  <FieldArray
                    name="specialtyIds"
                    render={(arrayHelpers) => (
                      <div>
                        {values.specialtyIds &&
                        values.specialtyIds.length > 0 ? (
                          <div className="mb-2">
                            {values.specialtyIds.map((_specialtyIds, index) => (
                              <div key={index} className="mb-2">
                                <div className="flex items-center">
                                  <div className="flex-grow">
                                    <SelectInput
                                      name={`specialtyIds.${index}`}
                                      options={
                                        data?.specialties?.map((s) => ({
                                          value: s.id,
                                          label: s.name,
                                        })) || []
                                      }
                                      isLoading={loading}
                                    />
                                  </div>
                                  <div className="ml-2">
                                    <Button
                                      type="button"
                                      kind="tertiary"
                                      color="red"
                                      size="sm"
                                      className="border"
                                      onClick={() => arrayHelpers.remove(index)}
                                    >
                                      <FAIcon icon="times" />
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            ))}
                            <div className="text-center">
                              <Button
                                type="button"
                                kind="tertiary"
                                color="blue"
                                size="xs"
                                onClick={() => arrayHelpers.push("")}
                              >
                                Add another specialty
                              </Button>
                            </div>
                          </div>
                        ) : (
                          <div className="text-center">
                            <Button
                              type="button"
                              kind="tertiary"
                              color="blue"
                              size="xs"
                              onClick={() => arrayHelpers.push("")}
                            >
                              Add a specialty
                            </Button>
                          </div>
                        )}
                      </div>
                    )}
                  />
                </VerticalField>
              </div>

              <div className="mt-3">
                <VerticalField label="State Licensures">
                  <FieldArray
                    name="stateIds"
                    render={(arrayHelpers) => (
                      <div>
                        {values.stateIds && values.stateIds.length > 0 ? (
                          <div className="mb-2">
                            {values.stateIds.map((_skillId, index) => (
                              <div
                                key={index}
                                style={{
                                  marginBottom: "0.5rem",
                                }}
                              >
                                <div className="flex items-center">
                                  <div className="flex-grow">
                                    <SelectInput
                                      name={`stateIds.${index}`}
                                      options={
                                        data?.states?.map((s) => ({
                                          value: s.id,
                                          label: s.name,
                                        })) || []
                                      }
                                      isLoading={loading}
                                    />
                                  </div>
                                  <div className="ml-3">
                                    <Button
                                      type="button"
                                      kind="tertiary"
                                      color="red"
                                      size="sm"
                                      className="border"
                                      onClick={() => arrayHelpers.remove(index)}
                                    >
                                      <FAIcon icon="times" />
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            ))}
                            <div className="text-center">
                              <Button
                                type="button"
                                kind="tertiary"
                                color="blue"
                                size="xs"
                                onClick={() => arrayHelpers.push("")}
                              >
                                Add another state licensure
                              </Button>
                            </div>
                          </div>
                        ) : (
                          <div className="text-center">
                            <Button
                              type="button"
                              kind="tertiary"
                              color="blue"
                              size="xs"
                              onClick={() => arrayHelpers.push("")}
                            >
                              Add a state licensure
                            </Button>
                          </div>
                        )}
                      </div>
                    )}
                  />
                </VerticalField>
              </div>

              <div className="mt-6 text-center">
                <Button
                  type="submit"
                  kind="primary"
                  color="gold"
                  isLoading={isSubmitting}
                  disabled={isSubmitting}
                >
                  Update Provider
                </Button>
              </div>
            </form>
          )}
        </Formik>
      )}
    </div>
  );
};
